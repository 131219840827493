const mixtureGraphQLAPI = process.env.MIXTURE_CATALOG_GRAPHQL_API;
const mixtureChannelId = process.env.MIXTURE_CHANNEL_ID;
const mixtureCampaignId = process.env.MIXTURE_CHAMPAIGN_ID;
const mixtureDataCaptureCampaignId = process.env.MIXTURE_DATA_CAPTURE_CHAMPAIGN_ID;

const generateCatalogQuery = (isDataCapture , isInterstitial) => {
    let campaignId, CATALOG_QUERY
    if(isInterstitial){
         campaignId = mixtureCampaignId
    }
    else if(isDataCapture){
        campaignId = mixtureDataCaptureCampaignId
   }
    return CATALOG_QUERY = `
    {
        campaigns(campaignIds: [${campaignId}]) {
               campaignId
               startDate
               name {
                    locale 
                    value
                }
               consumerActions {
                   trait {
                       id
                       name
                       desc
                   }
                   optIn {
                       id
                       text {
                           locale
                           value
                       }
                   }
               }
            }
     }
    `;
}

export const mixtureGraphQL = async (isDataCapture, isInterstitial) => {
    let catalogQuery  
    
    if(isDataCapture){
        catalogQuery = generateCatalogQuery(true,false)
    }
    else if(isInterstitial){
        catalogQuery = generateCatalogQuery(false,true)
    }
    try {
        const response = await fetch(mixtureGraphQLAPI, {
            method: "POST",
            headers: { 
                "Content-Type": "application/json",
                "channelId": mixtureChannelId
            },
            body: JSON.stringify({ query: catalogQuery })
          });
          
        if(!response.ok) {
            throw new Error('error')
        }
        const data = await response.json();
        return data?.data?.campaigns?.length > 0 && data?.data?.campaigns[0];        
    } catch (error) {
        throw error;
    }    
} 

