import 'url-search-params-polyfill'
import React, { useEffect } from 'react';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
const JanrainForm = dynamic(() => import('../Janrain/Janrain/HOC/JanrainForm'));
const DoubleOptInVerification = dynamic(() => import('../Janrain/components/DoubleOptInVerification/DoubleOptInVerification'));

export default function DoubleOptInVerificationBlock({document}) {

    const title = document?.fields?.title;
    const successText = document?.fields?.successText;
    const failureText = document?.fields?.failureText;

    useEffect(() => {

    }, []);

    return <JanrainForm needActiveSession={false}>
        <DoubleOptInVerification
            title={title}
            successText={successText}
            failureText={failureText}
        >
        </DoubleOptInVerification>
    </JanrainForm>
}

DoubleOptInVerificationBlock.propTypes = {
    document: PropTypes.object
};