import React from 'react'
import PropTypes from 'prop-types'
import {icons} from './icons';
import {Constants} from '../../adapters/helpers/Constants';


const Icon = ({className = '', name, viewBox, ariaHidden, size, color, rotate, roundedIcon, arialable}) => {

    if (!name) {
        return null
    }

    const newStyle = {
        fill: color && color,
        width: size && `${size}rem`,
        height: size && `${size}rem`,
        fontSize: size && `${size}rem`,
        transform: rotate && `rotate(${rotate}deg)`,
    }

    return (roundedIcon ? (
        <span className={`ob-icon-rounded-container -${roundedIcon} ${className}`} style={newStyle}>
            <svg className={`ob-icon ob-icon--${name}`}
                viewBox={`0 0 ${viewBox} ${viewBox}`}
                aria-hidden={ariaHidden}
                role={Constants.rolePresentation}
                aria-label={arialable ? arialable : arialable === null ? '' : roundedIcon}
            >
                {icons[name]()} 
            </svg>
        </span>
    ) : (
        <svg className={`ob-icon ob-icon--${name} ${className}`}
            viewBox={`0 0 ${viewBox} ${viewBox}`}
            aria-hidden={ariaHidden}
            style={newStyle}
            role={Constants.rolePresentation}
            aria-label={arialable ? arialable : arialable === null ? '' : roundedIcon}
        >
             {icons[name]()}
        </svg>
    ));
}

Icon.propTypes = {
    /**
    * The name of the icon e.g. chevron, facebook etc.
    * See icons.js for naming icons
    */
    name: PropTypes.string,

    /**
    * Override the default to hide icon from screen readers
    */
    ariaHidden: PropTypes.string,

    /**
    * Add custom styles to the icon
    */
    styles: PropTypes.object,

    /**
    * Rotate the icon a certain number of degrees
    */
    rotate: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    
    /**
    * The size of the icon in rems by default 1.6rem/16px  
    */
    size: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),

    /**
    * The color of the icon
    */
    color: PropTypes.string,

    roundedIcon: PropTypes.string,
    viewBox: PropTypes.number,
    className: PropTypes.string

};

Icon.defaultProps = {
    styles: {},
    viewBox: 32,
    rotate: 0
}

export default Icon