import React from 'react';
import ReactMarkdown from 'react-markdown';
import Block from '../../adapters/helpers/Block'
import PropTypes from 'prop-types';
import { MarkdownTextConstants } from '../../adapters/helpers/Constants'
import rehypeRaw from 'rehype-raw'

export function Markdown({ source, className }) {
    return (
        <ReactMarkdown
          children={source}
          className={className}
          escapeHtml={false} 
          rehypePlugins={[rehypeRaw]}
        />
    );
}

Markdown.propTypes = {
    source: PropTypes.any,
    className: PropTypes.string,
}

export default function MarkdownText(props) {
    const block = new Block(props);
    const document = block.getDocument();
    const classNames = document?.fields?.classNames;
    let ariaHidden = null;
    if (classNames && classNames.indexOf('aria-hidden') >= 0) {
        ariaHidden = true;
    }
    const Tag = props.isSpan ? 'span' : 'div';

    //to add custom attribute to link for GA tagging
    const renderers = {
        link: ({ children, href }) => (
            <a href={href} className="event_internal_link" data-action-detail={href}>
            {children}
            </a>
        )
    };

    return ((document?.fields) ?
        <Tag className={classNames} aria-hidden={ariaHidden}>
           <ReactMarkdown children={block.getFieldValue(MarkdownTextConstants.text)} escapeHtml={false}  />
        </Tag>
    :
        <Tag className={props.className} aria-hidden={ariaHidden}>
           <ReactMarkdown renderers={renderers} children={props.value} escapeHtml={false}  />
        </Tag>
    );
}

MarkdownText.propTypes = {
    value: PropTypes.string,
    isSpan: PropTypes.bool,
    className: PropTypes.string,
};
