import React from 'react';
import dynamic from 'next/dynamic';
import {isLoggedIn,setLoggedIn} from '../../adapters/helpers/Utils'
import DOMPurify from 'isomorphic-dompurify';
const Image = dynamic(() => import('../Image/Image'));
const Button = dynamic(() => import('../Button/Button'));

class PasswordProtectedBlock extends React.Component {
    constructor(props) {
        super(props);
        const encryptedPassword=this.props.document?.fields?.encryptedPassword;
        this.state ={            
        isLoggedIn :  isLoggedIn(encryptedPassword),
        isInvalidForm:false,
        inputPassword:undefined,
        }
    }
    componentDidUpdate() {
      const encryptedPassword=this.props.document?.fields?.encryptedPassword;
      const mwcElements=['HTML-Block-MWC-title','HTML-Block-HEALTHY-MOUTH-&-BODY-WHAT-YOU-NEED-TO-KNOW',
      'Block-Button-container-Read-full-article','Block---HTML-Block-video-files-block','HTML-Block-Contack-us-Media' ];
      const classElements=['mini-nav-mwc-media','ob-article-share','healthy-mouth-body'];
      const ImageElements= ['Content-Block-image-block---Block1','Article-Category-Hero-Block---PERSONALIZED-CLEAN','Article-Category-Hero-Block-Superior-Gum-Protect-Superior-Gum-Protection',
      'Article-Category-Hero-Block-REVOLUTIONARY-REAL-TIME-GUIDANCE','Article-Category-Hero-Block-ADA-SEAL-OF-ACCEPTANCE'];
      if(!isLoggedIn(encryptedPassword)){
          for(const element of mwcElements){
            if(document.getElementById(element)  && element){
              document.getElementById(element).style.display='none';
            }
          }
          for(const element of classElements){
            if(document.getElementsByClassName(element) && document.getElementsByClassName(element)[0]){
              document.getElementsByClassName(element)[0].style.display='none';
            }
          }
          for(const element of ImageElements){
            if(document.getElementById(element) && element){
              document.getElementById(element).style.visibility='hidden';
              document.getElementById(element).style.height='0';
            }
          }        
      }
    
    else{
      for(const element of mwcElements){
        if(document.getElementById(element) && element){
          document.getElementById(element).style.display='block';
        }
      }
      for(const element of classElements){
        if(document.getElementsByClassName(element) && document.getElementsByClassName(element)[0]){
          document.getElementsByClassName(element)[0].style.display='block';
        }
      }
      for(const element of ImageElements){
        if(document.getElementById(element)  && element){
          document.getElementById(element).style.visibility='visible';
          document.getElementById(element).style.height='auto';
        }
      } 
    }
  }
    
    componentDidMount() {
      const encryptedPassword=this.props.document?.fields?.encryptedPassword;
      const mwcElements=['HTML-Block-MWC-title','HTML-Block-HEALTHY-MOUTH-&-BODY-WHAT-YOU-NEED-TO-KNOW',
      'Block-Button-container-Read-full-article','Block---HTML-Block-video-files-block','HTML-Block-Contack-us-Media' ];
      const classElements=['mini-nav-mwc-media','ob-article-share','healthy-mouth-body'];
      const ImageElements= ['Content-Block-image-block---Block1','Article-Category-Hero-Block---PERSONALIZED-CLEAN','Article-Category-Hero-Block-Superior-Gum-Protect-Superior-Gum-Protection',
      'Article-Category-Hero-Block-REVOLUTIONARY-REAL-TIME-GUIDANCE','Article-Category-Hero-Block-ADA-SEAL-OF-ACCEPTANCE'];
      if(!isLoggedIn(encryptedPassword)){
          for(const element of mwcElements){
            if(document.getElementById(element) && element){
              document.getElementById(element).style.display='none';
            }
          }
          for(const element of classElements){
            if(document.getElementsByClassName(element) && document.getElementsByClassName(element)[0]){
              document.getElementsByClassName(element)[0].style.display='none';
            }
          }
          for(const element of ImageElements){
            if(document.getElementById(element)  && element){
              document.getElementById(element).style.visibility='hidden';
              document.getElementById(element).style.height='0';
            }
          }        
      }
    
    else{
      for(const element of mwcElements){
        if(document.getElementById(element)  && element){
          document.getElementById(element).style.display='block';
        }
      }
      for(const element of classElements){
        if(document.getElementsByClassName(element) && document.getElementsByClassName(element)[0]){
          document.getElementsByClassName(element)[0].style.display='block';
        }
      }
      for(const element of ImageElements){
        if(document.getElementById(element)  && element){
          document.getElementById(element).style.visibility='visible';
          document.getElementById(element).style.height='auto';
        }
      } 
    }
  }
 
    handleSubmit(e,encryptedPassword) {
        e.preventDefault();  
        const encryptPasswordInput= btoa(this.state.inputPassword)
        if (encryptPasswordInput===encryptedPassword) {
          this.setState({isInvalidForm: false,isLoggedIn:true});          
          setLoggedIn(encryptPasswordInput);
        }
        else {
         this.setState({isInvalidForm: true});
        }
    } 

    render() {       
    const title = this.props.document?.fields?.title;
    const passwordLabel = this.props.document?.fields?.passwordLabel;
    const subtitle= this.props.document?.fields?.subtitle;
    const errorText= this.props.document?.fields?.errorText;
    const encryptedPassword=this.props.document?.fields?.encryptedPassword;
    const passwordLockImage = this.props.document?.fields?.passwordAsset;   
    const CTALabel = this.props.document?.fields?.cta;    
    if(!isLoggedIn(encryptedPassword)){
    return (       
      <div>
        <div className="login-wrapper-password-block">
          <h1 className="title-text-password-block">{title}</h1>
            <div className="subtitle-text-pasword-block" dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(subtitle)
                }}>
              </div>
              <form onSubmit={e => this.handleSubmit(e,encryptedPassword)}>        
                {this.state.isInvalidForm &&
                  <div className="subtitle-text-pasword-block">{errorText}</div>
                }     
              <div className="label-container-block">
                <label className="label-password-block">
                   {passwordLockImage &&
                      <Image noLazyLoad={true} image={passwordLockImage} ariaHidden={true} />
                    }
                  <input type="password" placeholder={passwordLabel} className="password-input" value={this.state.inputPassword} onChange={e => this.setState({inputPassword:e.target.value })} />
                </label>
                </div>
              <div>                
                <Button 
                   disabled={this.state.inputPassword===undefined || this.state.inputPassword===''}
                    className="button-bg" role="button" type="submit">{CTALabel}</Button>
              </div>
            </form>
          </div> 
        </div>         
      )        
   }
      else{                
      return null;
   }
    }
}

export default PasswordProtectedBlock;