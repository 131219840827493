import React, {useCallback, useEffect, useRef, useState} from 'react';
import {FormInput, FormSelect, SubmitButton} from '../index';
import {inputs} from '../../Janrain/janrainForms/RegisterProductForm/inputs';
import {formatDate} from '../../../../adapters/helpers/Utils';
import {getProductVariantName} from '../../helpers/product.helpers';
import {Markdown} from '../../../MarkdownText/MarkdownText';
import throttle from 'lodash/throttle';
import DOMPurify from 'isomorphic-dompurify';
import dynamic from 'next/dynamic';
const Image = dynamic(() => import('../../../Image/Image'));
const Icon = dynamic(() => import('../../../Icon/Icon'));
const DotNav = dynamic(() => import('../../../DotNav/DotNav'));

const RegisterProduct = ({janrainLabels, dateOfPurchaseDisclaimer, dateOfPurchaseValidation, productCategoryList, productTypes, receiptPicture, setProductData, isCreatingProfile, setFormValidated, validPurchaseTimeWindow, warrantyExtensionTime, isGCSDown, formValidated, isSubmitting}) => {

    const [currentPage, setCurrentPage] = useState(0);
    const [numCarouselPages, setNumCarouselPages] = useState(0);
    const [currentProductList, setCurrentProductList] = useState([]);
    const [currentProductCategory, setCurrentProductCategory] = useState('');
    const [activeProductID, setActiveProductID] = useState(null);
    const [dateErrorText, setDateErrorText] = useState('');
    const [noWarrantyError, setNoWarrantyError] = useState('');
    const [productSelected, setProductSelected] = useState(false);
    const productCarouselRef = useRef(null);
    const dotNavRef = useRef(null);
    const locale = process.env.JANRAIN_CAPTURE_LOCALE;
    const commonControlBtnIconProps = {
        name: 'thinChevronDown',
        size: 2.4
    };

    const updateNumCarouselPages = () => {
        if (window && productCarouselRef?.current) {
            const carousel = productCarouselRef.current;
            const children = carousel.children;
            let fullCarouselWidth = 0;

            for(let i = 0; i < children.length; i++) {
                const element = children[i];

                let elementWidth = element.offsetWidth;
                const style = getComputedStyle(element);
                elementWidth += parseInt(style.marginLeft, 10) + parseInt(style.marginRight, 10);

                fullCarouselWidth += elementWidth;
            }

            if (children?.length % 3 === 1) {
                setNumCarouselPages(Math.ceil(fullCarouselWidth / carousel.offsetWidth));
            } else {
                setNumCarouselPages(Math.round(fullCarouselWidth / carousel.offsetWidth));
            }
        }
    };

    useEffect(() => {
        const handleResize = throttle(updateNumCarouselPages, 100);

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    });

    useEffect(() => {
        updateNumCarouselPages();
    }, [currentProductList]);

    const onScroll = useCallback(
        event => {
            if (!productCarouselRef.current) {
                return;
            }

            const currentRatio = event.target.scrollLeft / productCarouselRef.current.clientWidth;
            const min = Math.floor(currentRatio);

            let currentPosition = currentRatio < (min + 0.3) ? min : min + 1;
            currentPosition = Math.max(0, currentPosition);
            currentPosition = Math.min(currentProductList.length, currentPosition);

            setCurrentPage(currentPosition);
        },
        [productCarouselRef.current],
    );

    const handleDotClick = useCallback(i => {
        if(productCarouselRef.current) {
            const nextY = productCarouselRef.current.clientWidth * i;
            if(productCarouselRef.current.scrollTo) {
                productCarouselRef.current.scrollTo({ left: nextY, behavior: 'smooth'});
            } else {
                productCarouselRef.current.scrollLeft = nextY;
            }
        }
    }, []);

    const handleClick = event => {
        if (dotNavRef?.current && productCarouselRef?.current) {
            let activePageIndex = parseInt(dotNavRef.current.querySelector('.ob-dot-nav__item--active .ob-dot-nav__button').dataset.index, 10);
            const clickedButton = event.currentTarget.dataset.controlButton;
            if (clickedButton === 'next' && activePageIndex + 1 < numCarouselPages) {
                activePageIndex = activePageIndex + 1;
            } else if (clickedButton === 'prev' && activePageIndex - 1 >= 0) {
                activePageIndex = activePageIndex - 1;
            }
            const nextY = productCarouselRef.current.clientWidth * activePageIndex;
            if (productCarouselRef.current.scrollTo) {
                productCarouselRef.current.scrollTo({ left: nextY, behavior: 'smooth'});
            } else {
                productCarouselRef.current.scrollLeft = nextY;
            }
        }
    };

    const handleProductSelectClick = (productId, hasWarranty) => {
        if(hasWarranty) {
            setActiveProductID(productId);
            setProductSelected(true);
        }
    };

    const handleFormSelect = event => {
        event.stopPropagation();
        const value = event.currentTarget.value;
        let productVariantList = [];
        productCategoryList.forEach(category => {
            if (category.fields.name !== value) {
                return;
            }
            if (category?.fields?.productOverviewList) {
                category?.fields?.productOverviewList.forEach(productOverview => {
                    productOverview.fields.productVariants.forEach(productVariant => {
                        if(productVariant?.fields?.displayJanrainProduct === true) {
                            let janrainProductId = productVariant?.fields?.sku;
                            if(productVariant?.fields?.janrainProductId) {
                                janrainProductId = productVariant?.fields?.janrainProductId;
                            }
                            productVariant.fields['productId'] = janrainProductId;
                            productVariant.fields['displayName'] = productVariant?.fields?.name;
                            if(productVariant?.fields?.shortTitle) {
                                productVariant.fields['displayName'] = productVariant?.fields?.shortTitle;
                            }
                            productVariantList.push(productVariant);
                        }
                    });
                });
                setCurrentProductList(productVariantList);
            } else {
                setNoWarrantyError(janrainLabels.warrantyErrorLabel);
                setCurrentProductList([]);
            }
        });
        setCurrentProductCategory(value);
    };

    const validateDate = (inputValue) => {
        const regex = new RegExp(dateOfPurchaseValidation.fields.pattern);

        let inputDate = {};
        const currentDate = new Date();
        const validPurchaseWindow = new Date();
        validPurchaseWindow.setFullYear(validPurchaseWindow.getFullYear() - validPurchaseTimeWindow);

        switch (inputValue.indexOf('/')) {
            case 2: {
                let year = inputValue.substr(inputValue.length - 4);
                let month = inputValue.substring(3, 5);
                let day = inputValue.substring(0, 2);
                inputDate = new Date(`${year}-${month}-${day}T00:00:00`);
                break;
            }
            case 4: {
                inputDate = new Date(inputValue);
                break;
            }
            default: {
                inputDate = currentDate;
            }
        }

        let isValid = regex.test(inputValue) && inputDate < currentDate && inputDate.getFullYear() >= validPurchaseWindow.getFullYear();

        if (isValid) {
            setDateErrorText('');
            const cmsProductInfo = currentProductList.find(elem => elem?.fields?.productId === activeProductID);
            const productName = getProductVariantName(cmsProductInfo);
            const mainAsset = cmsProductInfo?.fields?.mainAsset;
            const today = new Date();
            // Get the date for the end of the month, one year later
            let warrantyExpirationDate = new Date(inputDate.getFullYear() + warrantyExtensionTime, inputDate.getMonth() + 1, inputDate.getDate()); // 0 gets the last day of the month

            let product = {
                traitDate: formatDate(inputValue, '/', 'dd-mm-yyyy'),
                purchaseDate: formatDate(inputValue, '/', 'dd-mm-yyyy'),
                productId: activeProductID,
                gCSProductId: cmsProductInfo?.fields?.gCSProductId,
                typeCode: cmsProductInfo?.fields?.typeCode,
                productName,
                mainAsset,
                warrantyExpirationDate: formatDate(warrantyExpirationDate, '/', 'dd-mm-yyyy'),
                warrantyExpirationDateInText: warrantyExpirationDate.toLocaleString(locale, { month: 'long', year: 'numeric'}),
                category: currentProductCategory,
                registrationDate: formatDate(today, '/', 'dd-mm-yyyy'),
                timeStamp: today.toUTCString()
            };

            setProductData(product);
            setFormValidated(true);
        } else {
            setDateErrorText(dateOfPurchaseValidation.fields.errorMessage);
            setFormValidated(false);
        }

    };

    const handleDateOfPurchase = event => {
        let value = event.currentTarget.value;
        if (event.currentTarget.type !== 'date') {
            // This autopopulates slashes in case input[type="date"] isn't supported
            // eslint-disable-next-line no-useless-escape
            event.currentTarget.value = value.replace(/^(\d\d)(\d)$/g,'$1/$2').replace(/^(\d\d\/\d\d)(\d+)$/g,'$1/$2').replace(/[^\d\/]/g,'');
        } else {
            value = value.replace(/-/g, '/')
        }
        if (activeProductID === null || activeProductID === '') {
            setDateErrorText(janrainLabels.noProductSelectedLabel);
        } else {
            validateDate(value);
        }
    };

    /* eslint-disable jsx-a11y/click-events-have-key-events */
    /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

    return (
        <div className={'ob-rp'}>
            <FormSelect
                dataArray={productTypes}
                name={inputs.field.selectProductType}
                isHalfWidth={true}
                selectLabel={janrainLabels.selectProductTypeLabel}
                value={currentProductCategory}
                onChange={handleFormSelect} />

            {currentProductList?.length > 0 ? (
                <>
                    <div className={'ob-rp__carousel'}>
                        <p className="ob-rp__carousel--title">{janrainLabels.selectProductLabel}</p>
                        <ul onScroll={onScroll} ref={productCarouselRef} className={`ob-rp__carousel--list ${numCarouselPages === 1 ? 'is-centered' : ''}`}>
                            {currentProductList.map((productVariant, index) => (
                                <li
                                    key={`${productVariant?.fields?.productId}-${index}`}
                                    className={`ob-rp__carousel--list-item ${activeProductID === productVariant?.fields?.productId ? 'is-active' : ''} ${activeProductID !== null && activeProductID !== productVariant?.fields?.janrainId ? 'is-inactive' : ''}`}
                                    onClick={() => handleProductSelectClick(productVariant?.fields?.productId, productVariant?.fields?.hasWarranty)} >
                                    { productVariant.fields.mainAsset &&
                                        <div>
                                            <Image image={productVariant.fields.mainAsset} />
                                            <p className={'ob-rp__carousel--list-text'}
                                               dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(productVariant?.fields?.displayName)}}>
                                            </p>
                                        </div>
                                    }
                                    { !productVariant.fields.hasWarranty &&
                                        <p className={'ob-input__error'}>
                                            {janrainLabels.noProductWarrantyLabel}
                                        </p>
                                    }
                                    { productVariant.fields.hasWarranty &&
                                        <div>
                                            <button aria-label={activeProductID === productVariant?.fields?.productId ?
                                                    janrainLabels.selectedProductButtonLabel : janrainLabels.registerProductProductSelect
                                                } className={'ob-rp__carousel--list-button'}
                                                    onClick={() => handleProductSelectClick(productVariant?.fields?.productId, productVariant?.fields?.hasWarranty)}
                                                    type={'button'}>
                                                {activeProductID === productVariant?.fields?.productId ?
                                                    janrainLabels.selectedProductButtonLabel : janrainLabels.registerProductProductSelect
                                                }
                                            </button>
                                        </div>
                                    }

                                </li>
                            ))}
                        </ul>
                        {numCarouselPages > 1 &&
                        <div>
                            <button className={'ob-rp__carousel--control-button'}
                                    data-control-button={'prev'}
                                    onClick={handleClick}
                                    aria-label={janrainLabels?.previousLabel}
                                    disabled={currentPage === 0}
                                    type={'button'}>
                                                <span>
                                                    <Icon {...commonControlBtnIconProps} rotate={90} />
                                                </span>
                            </button>
                            <button className={'ob-rp__carousel--control-button'}
                                    data-control-button={'next'}
                                    onClick={handleClick}
                                    aria-label={janrainLabels?.nextLabel}
                                    disabled={currentPage === numCarouselPages - 1}
                                    type={'button'}>
                                                    <span>
                                                        <Icon {...commonControlBtnIconProps} rotate={-90} />
                                                    </span>
                            </button>
                            <div ref={dotNavRef}>
                                <DotNav count={numCarouselPages}
                                        onClick={handleDotClick}
                                        current={currentPage}
                                        color={'blue'} />
                            </div>
                        </div>
                        }
                    </div>
                    {productSelected &&
                        <div className='ob-rp__dop-container'>
                            <div className='ob-rp__dop-input-container'>
                                <FormInput
                                    inputType={inputs.type.date}
                                    name={inputs.field.dateOfPurchase}
                                    placeholder={janrainLabels.dateOfPurchasePlaceholder}
                                    validationErrorText={dateErrorText}
                                    onChange={handleDateOfPurchase}
                                    label={janrainLabels.dateOfPurchaseLabel} />
                                <div className='ob-rp__dop-disclaimer'>
                                    <Markdown source={dateOfPurchaseDisclaimer} />
                                </div>
                                {!isCreatingProfile && !isGCSDown &&
                                    <SubmitButton
                                        isSubmitting={isSubmitting}
                                        disabled={!formValidated || isSubmitting}
                                        buttonText={janrainLabels.registerNewProductButtonLabel}
                                    />
                                }
                                {!isCreatingProfile && isGCSDown &&
                                    <p className={'ob-input__error'}>{janrainLabels.gcsDownErrorMessage}</p>
                                }
                            </div>
                            <Image image={receiptPicture} />
                        </div>
                    }
                </>
            ) : (
                noWarrantyError !== '' && <p className={'ob-input__error'}>{noWarrantyError}</p>
            )}
        </div>
    )
};

export default RegisterProduct;
