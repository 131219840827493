import Block from "../../adapters/helpers/Block";
import PropTypes from 'prop-types';
import React, { useState, useEffect, useReducer } from "react";
import dynamic from 'next/dynamic';
import {
  EasterEggreducer,
  initialState,
} from "../../adapters/reducers/reducers";
const SpotlightWaitlistIo10 = dynamic(() => import("../SpotlightWaitlistBlock/SpotlightWaitlightIO10"));

const EasterEggBlock = (props) => {
  const block = new Block(props);
  const easterEggModelDetails =
    block?.props?.document?.fields?.easterEggModelDetails;
  const waitlistBlockDataCollection =
    block?.props?.document?.fields?.waitlistBlockDataCollection;
  let [windowWidth, setWindowWidth] = useState(400);
  const [state, dispatch] = useReducer(EasterEggreducer, initialState);
  const [isShowEasterEggs, setShowEasterEggs] = useState(false);
  useEffect(() => {
    setShowEasterEggs(
      typeof window !== "undefined" && sessionStorage.getItem("showEggs")
    );
  }, []);

  function handleResize() {
    setWindowWidth(window.innerWidth);
  }

  const [isCanvasOpen, setIsCanvasOpen] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const onClickEasterEgg = () => {
    if (typeof window.dataLayer !== "undefined") {
      window.dataLayer.push({
        event: "customEvent",
        GAeventCategory: "event_informational_action",
        GAeventAction: "event_button_click",
        GAeventLabel: "easter_egg_clicked",
      });
    }
    setIsCanvasOpen(!isCanvasOpen);
  };

  return (
    <div
      className="image-container"
      style={{ margin: easterEggModelDetails?.margin }}
    >
      {isShowEasterEggs && (
        <div
          className="findeast"
          style={{
            background: `url(${easterEggModelDetails?.imageURL})`,
            backgroundRepeat: "no-repeat",
            top: `${easterEggModelDetails?.top}`,
            gridArea: `${easterEggModelDetails?.gridArea}`,
          }}
          onClick={onClickEasterEgg}
        ></div>
      )}
      {isCanvasOpen && (
        <SpotlightWaitlistIo10
          block={waitlistBlockDataCollection}
          eggFound={isCanvasOpen}
        />
      )}
    </div>
  );
};

EasterEggBlock.propTypes = {
  props: PropTypes.object,
};

export default EasterEggBlock;
