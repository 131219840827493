import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import { Waypoint } from 'react-waypoint';
import PropTypes from 'prop-types';
import { mediaQueryHOC, 
    Icon,
    SpotlightWaitlistConstants,
    Button,
    Image,
    CampaignIframeOverlayConstants,
    CheckboxInput,
    mixtureGraphQL,
    mixtureFormRegister,
    updateTraitsMixtureFormRegister,
    checkPattern,
    ImageFactory,
    SubmitButton,
    filterMethod,
    trim,
    globalEnrichmentEventPush,
    customStylesLegal
   } from './exportComponent';

function DataCaptureBlock(props) {
    const { document: doc, isLarge } = props;
    const className = doc?.fields?.classname;
    const oralBLogo = doc?.fields?.oralBLogo;
    const formHeading = doc?.fields?.formHeading;
    const formLabels = doc?.fields?.formLabels;
    const backgroundAsset = doc?.fields?.backgroundAsset
    const mobileBackgroundAsset = doc?.fields?.mobileBackgroundAsset ;
    const emailLimitExceedErrorText = doc?.fields?.emailLimitExceedErrorText;
    const stepOneTitle = doc?.fields?.stepOneTitle;
    const stepOneDescription = doc?.fields?.stepOneDescription;
    const disclaimerWithoutCheckbox = doc?.fields?.disclaimerWithoutCheckbox;
    const termsAndConditionsTop = doc?.fields?.termsAndConditionsTop;
    const termsAndConditionsBottom = doc?.fields?.termsAndConditionsBottom;
    const step2Title = doc?.fields?.step2Title;
    const rawTopicList = doc?.fields?.topicList ;
    const step2BackgroundAsset = doc?.fields?.step2BackgroundAsset;
    const step2MobileBackgroundAsset = doc?.fields?.step2MobileBackgroundAsset;
    const registerCtaText = doc?.fields?.registerCtaText ;
    const traitSelectionTitle = doc?.fields?.traitSelectionTitle;
    const traitSelectionDescription = doc?.fields?.traitSelectionDescription ;
    const traitSelectionValueList = doc?.fields?.traitSelectionValueList ;
    const submitCtaText = doc?.fields?.submitCtaText ;
    const brushNotSelectedErrorMessage = doc?.fields?.brushNotSelectedErrorMessage;
    const traitSelectionErrorMessage = doc?.fields?.traitSelectionErrorMessage;
    const thankyouTitle = doc?.fields?.thankyouTitle;
    const thankyouDescription = doc?.fields?.thankyouDescription;
    const thankyouBackgroundAsset = doc?.fields?.thankyouBackgroundAsset;
    const thankyouMobileBackgroundAsset = doc?.fields?.thankyouMobileBackgroundAsset;
    const modalLabelFirst = doc?.fields?.modalLabelFirst;
    const modalLabelSecond = doc?.fields?.modalLabelSecond;

    const [errorMessage, setErrorMessage] = useState('');
    const [traitErrorMessage, setTraitErrorMessage] = useState('');
    const [isFirstLegalModalOpen, setisFirstLegalModalOpen] = useState(false);
    const [isSecondLegalModalOpen, setIsSecondLegalModalOpen] = useState(false);
    const [serverSideErrorMessage, setServerSideErrorMessage] = useState(false);
    const [isDisplayBrushScreen, setDisplayBrushScreen] = useState(false);
    const [isTraitValueScreen, setTraitValueScreen] = useState(false);
    const [isThankYouScreen, setThankYouScreen] = useState(false);
    const [selectedOptionId, setSelectedOptionId] = useState();
    const [optionValue,setOptionText] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [topicOptions, setTopicOptions] = useState([]);
    const [hasAcceptedTermsAndConditions, setHasAcceptedTermsAndConditions] = useState(false);
    const [selectedBrushType, setSelectedBrushType] = useState('');
    const [selectedBrushEvent, setSelectedBrushEvent] = useState('');
    const [selectedTraitType, setSelectedTraitType] = useState([]);
    const [selectedTraitEvent, setSelectedTraitEvent] = useState([]);
    const [erroneousInputs, setErroneousInputs] = useState([]);
    const [traitsData, setTraitsData] = useState();
    const [optinsData, setOptinsData] = useState();
    const [participationID, setParticipationId] = useState();
    const [userData, setUserData] = useState({});
    const [isLoader, setIsLoader] = useState(false);
    const blockRef = useRef(null);
    const legalModalRef = useRef(null);
    const isError = erroneousInputs.length > 0;

    useEffect(() => {
        setTimeout(() => {
            setLegalModals();
        }, 1000)
    },[]);

    useEffect(() => {
        mixtureGraphQL(true, false).then((response) => {
            let traitsObj = filterMethod(response?.consumerActions, 'trait');
            let optinsObj = filterMethod(response?.consumerActions, 'optIn');
            setTraitsData(traitsObj);
            setOptinsData(optinsObj);
        }).catch((error) => {
            console.error(error);
        })
    }, []);

    const closeLegalModal = () => {
        setisFirstLegalModalOpen(false);
        setIsSecondLegalModalOpen(false);
    };

    const setLegalModals = () => {
        if (blockRef.current) {
            let firstModalId = blockRef.current.querySelector('#openFirstLegalModal');
            if (firstModalId) {
                firstModalId.addEventListener('click', (e) => {
                    e.preventDefault();
                    setisFirstLegalModalOpen(true);
                })
            }
            let secondModalId = blockRef.current.querySelector('#openSecondLegalModal');
            if (secondModalId) {
                secondModalId.addEventListener('click', (e) => {
                    e.preventDefault();
                    setIsSecondLegalModalOpen(true);
                })
            }
        }
    };

    const handleMouseOver = (event, image) => {
        event.currentTarget.src = image;
    }
    const handleMouseOut = (event, image) => {
        event.currentTarget.src = image;
    }
    const changeUserData = (evt, id) => {
        const userDetail = {...userData, [id]: evt?.target?.value}
        setUserData(userDetail);
        if(isError) {
            const inputElement = document.getElementById(id);
            inputElement.classList.remove('--error');
            const updatedErrorInputs = erroneousInputs.filter(errorInput => errorInput?.id !== id);
            setErroneousInputs(updatedErrorInputs);
        }
        serverSideErrorMessage && setServerSideErrorMessage(false);
        errorMessage && setErrorMessage('');
    }

    const getSeclectedOptionId = (selectedBrushType) => {
        for (let i = 0; i < topicOptions.length; i++) {
            if (topicOptions[i]?.optiontext === (selectedBrushType && selectedBrushType.trim())) {
                return topicOptions[i];
            }
        }
        return null;
    }

    const handleBrushSelection = (selectedBrush, eventLabel) => {
        setErrorMessage('');
        setSelectedBrushEvent(eventLabel)
        if(selectedBrushType === selectedBrush) {
            setSelectedBrushType('');
            setSelectedOptionId(0);
        } else {
            const brushDetails = getSeclectedOptionId(selectedBrush);
            setSelectedOptionId(brushDetails?.optionId);
            setOptionText(brushDetails?.optiontext);
            setSelectedBrushType(selectedBrush);
        }
    };

    const handleTraitSelection = (selectedTrait, topic, index, eventLabel) => {
        const imageclass = document.getElementsByClassName('ob-trait-image')[index];
        const srcImage = `https:${topic?.fields?.icon?.fields?.asset?.fields?.file?.url}`
        const hoverImage = `https:${topic?.fields?.hoverIcon?.fields?.asset?.fields?.file?.url}`
        if (imageclass.src === srcImage || imageclass.src === hoverImage) {
            imageclass.src = `https:${topic?.fields?.selectedIcon?.fields?.asset?.fields?.file?.url}`;
        }
        else {
            imageclass.src = `https:${topic?.fields?.icon?.fields?.asset?.fields?.file?.url}`;
        }
        setTraitErrorMessage('');
        if(selectedTraitType.includes(selectedTrait)){
            setSelectedTraitType(selectedTraitType.filter((selectedTraitValue) => selectedTraitValue !== selectedTrait ))
        }
        else{
            setSelectedTraitType([...selectedTraitType, selectedTrait])
        }
        if(selectedTraitEvent.includes(eventLabel)){
            setSelectedTraitEvent(selectedTraitEvent.filter((selectedTraitEventValue) => selectedTraitEventValue !== eventLabel))
        }
        else{
            setSelectedTraitEvent([...selectedTraitEvent, eventLabel])
        }
    };

    const traitResponseEventPush = (responseValue) => {
        if (typeof window.dataLayer !== 'undefined') {
            let eventObj;
            if (responseValue) {
                responseValue.forEach((value) => {
                    eventObj = {
                        'event': CampaignIframeOverlayConstants?.customEvent,
                        'GAeventCategory': CampaignIframeOverlayConstants?.event_survey_action,
                        'GAeventAction': CampaignIframeOverlayConstants?.event_survey_question,
                        'GAeventLabel': `${CampaignIframeOverlayConstants?.crm_enrichment_oralcare_}${value}`
                    }
                    window.dataLayer.push(eventObj);
                })
            }
        } 
        
    };

    const isMandatoryBrushTypeNotSelected = () => brushNotSelectedErrorMessage && !selectedBrushType;

    const isAtLeastOneTraitNotSelected = () => traitSelectionErrorMessage && !selectedTraitType.length;

    const handleSubmit = async event => {
        event.preventDefault();
        setIsSubmitting(true);
        setIsLoader(true);
        const form = event.target;    
        const inputs = form.querySelectorAll('input, select');
        let errors = [];

        for(let i = 0; i < inputs.length; i++) {
            inputs[i].classList.remove('--error');
            if (inputs[i].hasAttribute('required')) {
                if (inputs[i].value.length === 0) {
                    errors.push({id: inputs[i].getAttribute('id')});
                    inputs[i].classList.add('--error');
                } else if (inputs[i].hasAttribute('pattern')) {
                    if (checkPattern(inputs[i])) {
                        errors.push({id: inputs[i].getAttribute('id')});
                        inputs[i].classList.add('--error');
                    }
                }
            } else if (inputs[i].value.length > 0 && inputs[i].hasAttribute('pattern')) {
                if (checkPattern(inputs[i])) {
                    errors.push({id: inputs[i].getAttribute('id')});
                    inputs[i].classList.add('--error');
                }
            }
        }
        const email = form?.email?.value;
        const firstName = trim(form?.firstName?.value);
        if(errors.length === 0 && email) {
            let arguObj = {
                firstName: firstName,
                email: email,
                hasAcceptedTermsAndConditions: hasAcceptedTermsAndConditions,
                selectedBrushType: null,
                traitsData: traitsData,
                optinsData: optinsData,
                emailDisclaimerTop: termsAndConditionsTop,
                campaignSource: SpotlightWaitlistConstants.fromSourceDataCapture,
                dataCapture: true
            }
            await mixtureFormRegister(arguObj)
                .then((response) => {
                    if (response?.status === 'SUCCESS') {
                        setParticipationId(response['participationId'])
                        setIsLoader(false);
                        setDisplayBrushScreen(true);
                        if (typeof (window.dataLayer) !== 'undefined') {
                            globalEnrichmentEventPush(CampaignIframeOverlayConstants?.event_crm_action, CampaignIframeOverlayConstants?.event_profile_email_signup, CampaignIframeOverlayConstants?.crm_enrichment_signup)
                        }
                    } else {
                        setIsSubmitting(false);
                        setIsLoader(false);
                        return response?.json();
                    }
                }).then((response) => {
                    if (response && response.status && response.code && response.code === 'ALREADY_PARTICIPATED') {
                        setServerSideErrorMessage(emailLimitExceedErrorText);                        
                    }                    
                }).catch((error) => {
                    console.log('error log:', error);
                    setIsSubmitting(false);
                    setIsLoader(false);
                });
            setIsSubmitting(false);
        } else {
            setErroneousInputs([...errors]);
            setIsSubmitting(false);
            setIsLoader(false)
        }
    }

    const handleBrushSelectionSubmit = async () => {    
        if(isMandatoryBrushTypeNotSelected()) {
            setErrorMessage(brushNotSelectedErrorMessage);
            setIsSubmitting(false);
            return;
        }
        else{
            globalEnrichmentEventPush(CampaignIframeOverlayConstants?.event_survey_action, CampaignIframeOverlayConstants?.event_survey_question, `${CampaignIframeOverlayConstants?.crm_enrichment_currentbrush_}${selectedBrushEvent}`);
            globalEnrichmentEventPush(CampaignIframeOverlayConstants?.event_survey_action, CampaignIframeOverlayConstants?.event_survey_complete, CampaignIframeOverlayConstants?.crm_enrichment_currentbrush_submission);
            setTraitValueScreen(true);
        }
       
    };

    const handleTraitSubmittion = async event => {
        if(isAtLeastOneTraitNotSelected()) {
            setTraitErrorMessage(traitSelectionErrorMessage);
            setIsSubmitting(false);
            return;
        }
        event.preventDefault();
        setIsSubmitting(true);
        setIsLoader(true);
        let arguObj = {
            selectedBrushType: selectedBrushType,
            traitsValue: selectedTraitType,
            participationID: participationID,
            traitsData: traitsData
        }
        await updateTraitsMixtureFormRegister(arguObj)
            .then((response) => {
                if (response?.status === 'Success') {
                    setIsLoader(false);
                    setThankYouScreen(true);
                    traitResponseEventPush(selectedTraitEvent);
                    globalEnrichmentEventPush(CampaignIframeOverlayConstants?.event_survey_action, CampaignIframeOverlayConstants?.event_survey_complete, CampaignIframeOverlayConstants?.crm_enrichment_oralcare_submission);
                } else {
                    setIsSubmitting(false);
                    setIsLoader(false);
                    return response?.json();
                }
            }).then((response) => {
                if (response && response.status && response.code && response.code === 'ALREADY_PARTICIPATED') {
                    setServerSideErrorMessage(emailExceedLimitLabel);                        
                }                    
            }).catch((error) => {
                console.log('error log:', error);
                setIsSubmitting(false);
                setIsLoader(false);
            });
    }

    const renderInputScreen = () => {
        return (
            <>
                <Image
                    className="screen-1-background-image"
                    image={
                        backgroundAsset && !isLarge
                            ? backgroundAsset
                            : mobileBackgroundAsset
                    }
                >
                    <div className="ob-data-capture-form">
                        <h1
                            className="ob-data-capture-form-title"
                            dangerouslySetInnerHTML={{ __html: stepOneTitle }}
                        ></h1>
                        <p className="ob-data-capture-form-description">
                            {stepOneDescription}
                        </p>
                        <p className="ob-data-capture-form-heading">
                            {formHeading}
                        </p>
                        {(errorMessage || serverSideErrorMessage) && (
                            <div
                                tabIndex="0"
                                className={
                                    "ob-focus-error ob-brush-select__system-error"
                                }
                            >
                                {errorMessage || serverSideErrorMessage}
                            </div>
                        )}
                        <form
                            className={`ob-data-capture-form-details ${className}`}
                            onSubmit={handleSubmit}
                            noValidate
                        >
                            <ul className={"ob-data-capture-form-details-list"}>
                                {formLabels?.map(
                                    (formLabel, formLabelIndex) => (
                                        <li
                                            className={
                                                "ob-data-capture-form-details-list-item"
                                            }
                                            key={"formLabel-" + formLabelIndex}
                                        >
                                            {formLabel.fields.type ===
                                            "textArea" ? (
                                                <textarea
                                                    cols={51}
                                                    rows={3}
                                                    className={
                                                        "ob-data-capture-form-details-list-item-input"
                                                    }
                                                    placeholder={
                                                        formLabel.fields.label
                                                            .fields.text
                                                    }
                                                    id={formLabel?.fields?.id}
                                                    value={
                                                        userData &&
                                                        userData[
                                                            formLabel?.fields
                                                                ?.id
                                                        ]
                                                    }
                                                    onChange={(evt) =>
                                                        changeUserData(
                                                            evt,
                                                            formLabel?.fields
                                                                ?.id
                                                        )
                                                    }
                                                    maxLength={
                                                        formLabel?.fields
                                                            ?.maxLength
                                                    }
                                                    name={formLabel?.fields?.id}
                                                />
                                            ) : (
                                                <input
                                                    type={
                                                        formLabel?.fields?.type
                                                    }
                                                    pattern={
                                                        formLabel?.fields
                                                            ?.validation?.fields
                                                            ?.pattern
                                                    }
                                                    required={
                                                        formLabel?.fields
                                                            ?.validation?.fields
                                                            ?.required
                                                    }
                                                    placeholder={
                                                        formLabel?.fields?.label
                                                            ?.fields?.text
                                                    }
                                                    id={formLabel?.fields?.id}
                                                    name={formLabel?.fields?.id}
                                                    value={
                                                        userData &&
                                                        userData[
                                                            formLabel?.fields
                                                                ?.id
                                                        ]
                                                    }
                                                    maxLength={
                                                        formLabel?.fields
                                                            ?.maxLength
                                                    }
                                                    onChange={(evt) =>
                                                        changeUserData(
                                                            evt,
                                                            formLabel?.fields
                                                                ?.id
                                                        )
                                                    }
                                                    className={
                                                        "ob-data-capture-form-details-list-item-input"
                                                    }
                                                />
                                            )}

                                            {formLabel?.fields
                                                ?.fieldMention && (
                                                <p
                                                    className={
                                                        "ob-data-capture-form-details-list-item-mention"
                                                    }
                                                >
                                                    {
                                                        formLabel?.fields
                                                            ?.fieldMention
                                                    }
                                                </p>
                                            )}

                                            {erroneousInputs.length > 0 &&
                                                erroneousInputs.map(
                                                    (error, index) => {
                                                        return (
                                                            error.id ===
                                                                formLabel.fields
                                                                    .id &&
                                                            formLabel?.fields
                                                                ?.validation
                                                                ?.fields
                                                                ?.errorMessage && (
                                                                <p
                                                                    key={index}
                                                                    className={
                                                                        "ob-data-capture-form-details-list-item-error"
                                                                    }
                                                                >
                                                                    {
                                                                        formLabel
                                                                            ?.fields
                                                                            ?.validation
                                                                            ?.fields
                                                                            ?.errorMessage
                                                                    }
                                                                </p>
                                                            )
                                                        );
                                                    }
                                                )}
                                        </li>
                                    )
                                )}
                                {disclaimerWithoutCheckbox && (
                                    <li className={'legal-text-disclaimer'}>{disclaimerWithoutCheckbox}</li>
                                )}
                                {termsAndConditionsTop && (
                                    <li
                                        className={
                                            "ob-data-capture-form-details-legal-text-checkbox"
                                        }
                                    >
                                        <CheckboxInput
                                            label={`<p>${termsAndConditionsTop}</p>`}
                                            name={
                                                SpotlightWaitlistConstants.checkBoxText
                                            }
                                            value={
                                                SpotlightWaitlistConstants.checkBoxText
                                            }
                                            checked={
                                                hasAcceptedTermsAndConditions
                                            }
                                            onChange={() =>
                                                setHasAcceptedTermsAndConditions(
                                                    !hasAcceptedTermsAndConditions
                                                )
                                            }
                                        />
                                    </li>
                                )}
                                <li
                                    className={
                                        "ob-data-capture-form-details-cta-section"
                                    }
                                >
                                    <SubmitButton
                                        buttonText={registerCtaText}
                                        disabled={
                                            !hasAcceptedTermsAndConditions
                                        }
                                        isSubmitting={isSubmitting}
                                    />
                                </li>
                            </ul>
                        </form>
                        {termsAndConditionsBottom && (
                            <div className="ob-data-capture-form-details-legal-text-wrapper">
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: termsAndConditionsBottom,
                                    }}
                                    className={"legal-text"}
                                />
                            </div>
                        )}
                    </div>
                </Image>
            </>
        );
        
    }

    const renderBrushSelectionScreen =() => {
        setTimeout(() => {
            window.scrollTo(0, 0)
        }, 100)
        return <>
            <Image className='screen-2-background-image' image={(step2BackgroundAsset && !isLarge) ? step2BackgroundAsset : step2MobileBackgroundAsset}>
                <div className='ob-brush-select'>
                    <h2 className='ob-brush-select-title' dangerouslySetInnerHTML={{ __html: step2Title }}></h2>
                    {rawTopicList && 
                        <div className={'ob-brush-select-list'}>
                            {
                                rawTopicList.map((topic, topicIndex) => {
                                    const value = topic?.fields?.value;
                                    const contentImageId = topic?.fields?.icon?.fields?.asset?.fields?.file?.url;
                                    const imageSrc = contentImageId && ImageFactory.buildContentfullImageUrl(contentImageId);
                                    const hoverContentImageId = topic?.fields?.hoverIcon?.fields?.asset?.fields?.file?.url;
                                    const hoverImageSrc = hoverContentImageId && ImageFactory.buildContentfullImageUrl(hoverContentImageId);
                                    const eventLabelText = topic?.fields?.eventLabelText;
                                    return <div className={'ob-brush-select-list-item'} key={'formLabel-' + topicIndex}>
                                        <button className={`ob-brush-select-brush-type ${value === selectedBrushType ? 'ob-brush-select-brush-type-selected' : ''}`} value={value} onClick={() => handleBrushSelection(value, eventLabelText)}>
                                            <img className='ob-mainAsset-wrapper-img' src={imageSrc} aria-hidden={true}
                                                onMouseOver={(event) => handleMouseOver(event, hoverImageSrc)}
                                                onMouseOut={(event) => handleMouseOut(event, imageSrc)} 
                                            />
                                        </button>
                                    </div>
                                })
                            }
                        </div>
                    }
                    {(errorMessage || serverSideErrorMessage) &&
                        <div tabIndex='0' className={'ob-focus-error ob-brush-select__system-error'}>
                            {errorMessage || serverSideErrorMessage}
                        </div>
                    }
                    <div className={'ob-brush-select-cta'}>
                        <Button 
                            aria-label={`${SpotlightWaitlistConstants.next}`}
                            id={'brush-select-next-cta'} 
                            className='ob-brush-select-cta-next' 
                            onClick={handleBrushSelectionSubmit}
                        >
                            {SpotlightWaitlistConstants.next}
                        </Button>
                    </div>
                </div>
            </Image>
        </>
    }

    const renderTraitSelectionScreen =() => {
        setTimeout(() => {
            window.scrollTo(0, 0)
        }, 100)
        return <>
            <Image className='screen-2-background-image' image={(step2BackgroundAsset && !isLarge) ? step2BackgroundAsset : step2MobileBackgroundAsset}>
                <div className='ob-trait-value'>
                    <h2 className='ob-trait-value-title' dangerouslySetInnerHTML={{ __html: traitSelectionTitle }}></h2>
                    <p className='ob-trait-value-description' dangerouslySetInnerHTML={{ __html: traitSelectionDescription }}></p>
                    {rawTopicList && 
                        <div className={'ob-trait-value-list'}>
                            {
                                traitSelectionValueList.map((topic, topicIndex) => {
                                    const value = topic?.fields?.value;
                                    const eventLabelText = topic?.fields?.eventLabelText;
                                    return <div className={'ob-trait-value-list-item'} key={'formLabel-' + topicIndex}>
                                        <button disabled={isSubmitting} className={`ob-trait-value-type ${value === selectedTraitType ? 'ob-trait-value-type-selected' : ''}`} value={value} onClick={() => handleTraitSelection(value, topic, topicIndex, eventLabelText)}>
                                            {/* <Image image={topic?.fields?.icon} classname={'ob-trait-image'} /> */}
                                            <div className='ob-mainAsset-wrapper'>
                                                <img className='ob-mainAsset-wrapper-img ob-trait-image' src={`https:${topic?.fields?.icon?.fields?.asset?.fields?.file?.url}`}/>
                                            </div>
                                            <span dangerouslySetInnerHTML={{ __html: topic?.fields?.label }} />
                                        </button>
                                    </div>
                                })
                            }
                        </div>
                    }
                    {(traitErrorMessage) &&
                        <div tabIndex='0' className={'ob-focus-error ob-brush-select__system-error'}>
                            {traitErrorMessage}
                        </div>
                    }
                    <div className={'ob-trait-value-cta'}>
                        <Button 
                            aria-label={submitCtaText} 
                            className='ob-submit-cta' 
                            onClick={handleTraitSubmittion}
                        >
                            {submitCtaText}
                        </Button>
                    </div>
                </div>
            </Image>
        </>
    }

    const renderThankYouScreen =() => {
        setTimeout(() => {
            window.scrollTo(0, 0)
        }, 100)
        return <>
            <Image className='thank-you-background-image' image={(thankyouBackgroundAsset && !isLarge) ? thankyouBackgroundAsset : thankyouMobileBackgroundAsset}>
                <div className='ob-thank-you-screen'>
                        {oralBLogo &&
                            <div className='ob-contentBlock-text-image'>
                                <Image image={oralBLogo} />
                            </div>
                        }
                    <h2 className='ob-thank-you-screen-title' dangerouslySetInnerHTML={{ __html: thankyouTitle }}></h2>
                    <p className='ob-thank-you-screen-description' dangerouslySetInnerHTML={{ __html: thankyouDescription }}></p>
                </div>
            </Image>
        </>
    }

    const renderAllScreens = () => {
        return <>
            {isDisplayBrushScreen ?  (isTraitValueScreen ? (isThankYouScreen ? renderThankYouScreen() : renderTraitSelectionScreen()) : renderBrushSelectionScreen()) : renderInputScreen()}
        </>
    }

    return (
        <section className='data-capture-register'>
            <Waypoint onLeave={closeLegalModal} />
                <div className='ob-register js-modal-container' ref={blockRef}>
                <div className='ob-form__layout ob-register__wrapper'>
                    {renderAllScreens()}
                </div>
                    {(modalLabelFirst || modalLabelSecond) &&
                        <Modal
                            isOpen={isFirstLegalModalOpen || isSecondLegalModalOpen}
                            style={customStylesLegal}
                            onRequestClose={closeLegalModal}
                            closeTimeoutMS={250}
                            overlayClassName={'event_button_click'}
                            portalClassName='data-capture-register-legal-modal'
                            ref={legalModalRef}
                        >
                            <div className='ob-register__modal-container'>

                                {isFirstLegalModalOpen && modalLabelFirst &&
                                    <p dangerouslySetInnerHTML={{ __html: modalLabelFirst }}></p>
                                }

                                {isSecondLegalModalOpen && modalLabelSecond &&
                                    <p dangerouslySetInnerHTML={{ __html: modalLabelSecond }}></p>
                                }

                                <button className='event_close_window ob-modal-close'
                                    onClick={closeLegalModal}
                                    aria-label={'close'}
                                    type='button'
                                >
                                    <Icon name='close' size='2.4' />
                                </button>

                            </div>
                        </Modal>
                    }
                </div>
            <Waypoint onLeave={closeLegalModal} />
        </section>
    )

}

export default mediaQueryHOC(DataCaptureBlock);

DataCaptureBlock.propTypes = {
    document: PropTypes.object,
    isLarge: PropTypes.bool,
    index: PropTypes.any
};
