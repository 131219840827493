import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { mediaQueryHOC } from '../../adapters/helpers/Hooks';
import { isObjectEmpty } from '../../adapters/helpers/Utils';
import Block from '../../adapters/helpers/Block';
import dynamic from 'next/dynamic';
const DropDownSelector = dynamic(() => import('../DropDownSelector/DropDownSelector'));
import { MetaConstants, Constants } from '../../adapters/helpers/Constants';
import { useTabContext } from '../TabContext';

const SwitchTab = (props) => {
   
    const document = props?.document;
    const contentDetails = document?.fields?.contentDetails;
    const [selectedSwitchTypeIndex, setSelectedSwitchTypeIndex] = useState(0);
    const {activeTabIndex, setActiveTabIndex} = useTabContext() 
    const isDropDown = document?.fields?.isDropDown;
    const hasAllProducts = document?.fields?.hasAllProducts;
    const anchorId = document?.fields?.anchorId;
    const className = document?.fields?.classNames;
    const isMultipleTabs = document?.fields?.isMultipleTabs;
    const isMobileDropDown = document?.fields?.isMobileDropdown;
    let switchType;
    switchType = Array.isArray( contentDetails ) && contentDetails.length > ( hasAllProducts ? selectedSwitchTypeIndex : activeTabIndex ) && contentDetails[hasAllProducts ? selectedSwitchTypeIndex : activeTabIndex];
    if ( hasAllProducts && isMultipleTabs && !isDropDown) {
        switchType = Array.isArray( contentDetails ) && contentDetails.length > ( selectedSwitchTypeIndex ) && contentDetails[selectedSwitchTypeIndex];
    }
    if ( !isDropDown ) {
        switchType = Array.isArray( contentDetails ) && contentDetails.length > ( selectedSwitchTypeIndex ) && contentDetails[selectedSwitchTypeIndex];
    }
    let contents = switchType?.fields?.contents;
    
    const handleSwitchTypeChange = (index) => {
        setSelectedSwitchTypeIndex( index );
        setActiveTabIndex(index)
    };

    for ( let i = 0; i < contents.length; i++ ){
        if ( contents?.[i]?.fields?.contentType === 'switchTabs' ) {
            if ( !contents[i].fields.onClickCallback ) {
                contents[i].fields.onClickCallback = props?.onClickCallback;
            }
        } else if ( contents?.[i]?.fields?.contentType === 'productRecommendBlock' ) {
            if ( !contents[i].fields.onClickCallback ) {
                contents[i].fields.onClickCallback = props?.onClickCallback ? props?.onClickCallback : props?.document?.fields?.onClickCallback;
            }
        } 
    }

    return (
        <section className={`ob-switch-tab-block ${className ? className: ''}`} id={anchorId}>
           <h3 className='ob-switch-tab-title'>{document?.fields?.title}</h3>
           {document?.fields?.doesHaveHorizontalLineBelowMainTitle && <hr></hr>}
           {isDropDown || (isMobileDropDown && props.isSmall) ? (
                <div className='ob-switch-dropdown-container'>
                    <DropDownSelector
                        options={contentDetails} selectedOption={!hasAllProducts ? activeTabIndex : selectedSwitchTypeIndex} handleOptionClick={handleSwitchTypeChange} anchorId={anchorId}
                    />
                </div>
                
            ) : (
                    <>
                        {!isMultipleTabs ? (Array.isArray(contentDetails) && <ul role='tablist' className={'ob-switch-tab__content-details'}>
                            {contentDetails && contentDetails.map((switchtab, index) => {
                                const swtichTypeCornerClass = index === 0 ? 'ob-switch-tab__content-details-left' : (index === contentDetails.length - 1 ? 'ob-switch-tab__content-details-right' : '');
                                return <li role='tab' key={index}>
                                        <button
                                            className={`ob-switch-tab__content-details-type ${index === selectedSwitchTypeIndex ? 'ob-switch-tab__active-switch' : ''} ${swtichTypeCornerClass}`}
                                            onClick={() => setSelectedSwitchTypeIndex( index )} key={index} tabIndex={index}>{switchtab?.fields?.label}
                                        </button>
                                    </li>
                                })}
                        </ul>
                        ) : (
                                Array.isArray(contentDetails) && <ul role='tablist' className={'ob-switch-tab-multiple__content-details'}>
                                    {contentDetails && contentDetails.map( ( switchtab, index ) => {
                                    return <li role='tab' key={index}>
                                        <a
                                        className={`ob-switch-tab__content-details-type ${index === selectedSwitchTypeIndex ? 'ob-switch-tab__active-switch' : ''}`}
                                            onClick={() => setSelectedSwitchTypeIndex( index )} key={index} tabIndex={index}>
                                            <img
                                                className="switch-tab-image-asset"
                                                src={MetaConstants.https + switchtab?.fields?.switchTabAsset?.fields?.file?.url}
                                                alt={Constants.productType}
                                            />
                                            <div className='switch-tab-label'>{switchtab?.fields?.label}</div>
                                        </a>
                                        <div className={`switch-tab-bottom-arrow ${index === selectedSwitchTypeIndex ? 'active' : ''}`} />
                                    </li>
                                })}
                        </ul>
                        )}
                    </>
            )}
            {!isObjectEmpty(contents) &&
                new Block(contents).renderSpecifiedBlock(contents)
            }
        </section>
    )
};

SwitchTab.propTypes = {
    document: PropTypes.object
};

export default mediaQueryHOC(SwitchTab);
