import React, {useCallback, useEffect, useRef, useState} from 'react';
import dynamic from 'next/dynamic';
import throttle from 'lodash/throttle';
import Modal from 'react-modal';
import {customJsonstringify, isObjectEmpty, toggleScroll} from '../../../../adapters/helpers/Utils';
import { WarrantyExtensionConstants } from '../../../../adapters/helpers/Constants';
import {
    removeProductFromDisplayedList, removeProductFromJanrainList 
} from './../../helpers/product.helpers';
import {
    deleteIsCreatingAccountFromLocalStorage, getJanrainCaptureProfileDataFromLocalStorage,
} from '../../helpers/localstorage.helpers';
import {
    postReplaceEntityUpdate
} from '../../../../adapters/model/service/api/svc';
import {getPostReplaceEntityObject} from '../../../../adapters/model/service/api/svc';
import {postWarrantyRegistrationUpdate} from '../../../../adapters/model/service/warrantyExtension/warrantyExtension.helpers';
const ResponsiveImage = dynamic(() => import('../../../ResponsiveImage/ResponsiveImage'));
import DOMPurify from 'isomorphic-dompurify';
import { optimizeImage } from '../../../../adapters/helpers/pagespeedHelper';
const MarkdownText = dynamic(() => import('../../../MarkdownText/MarkdownText'));
const Eyebrow = dynamic(() => import('../../../Eyebrow/Eyebrow'));
const Image = dynamic(() => import('../../../Image/Image'));
const Button = dynamic(() => import('../../../Button/Button'));
const Icon = dynamic(() => import('../../../Icon/Icon'));
const DotNav = dynamic(() => import('../../../DotNav/DotNav'));
const Label = dynamic(() => import('../../../Label/Label'));

const ProductManagement = ({oralbProductList, profileProductManagementTab, commonLabels, customStyles, closeModalTimeoutMS, setOralbProductList, isGCSAPIDown}) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [numCarouselPages, setNumCarouselPages] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [lastScroll, setLastScroll] = useState(0);
    const [requestedProduct, setRequestedProduct] = useState({});
    const [currentProduct, setCurrentProduct] = useState({});
    const [isDeleting, setIsDeleting] = useState(false);
    const [isProgress, setIsProgress] = useState(false);
    const productCarouselRef = useRef(null);
    const dotNavRef = useRef(null);
    const viewWarrantyLink = profileProductManagementTab?.viewWarrantyLink?.fields;
    const registeredProductLimit = profileProductManagementTab?.registeredProductLimit;
    const dummyImage = profileProductManagementTab?.dummyImage;

    const janrainLabels = {
        title: profileProductManagementTab?.title,
        profileIntroText: profileProductManagementTab?.profileIntroText,
        productManagementText: profileProductManagementTab?.productManagementText,
        primaryButtonLabel: profileProductManagementTab?.primaryButtonLabel?.fields?.text,
        makePrimaryButtonLabel: profileProductManagementTab?.makePrimaryButtonLabel?.fields?.text,
        deleteLabel: profileProductManagementTab?.delete?.fields?.text,
        registeredOn: profileProductManagementTab?.registeredOn?.fields?.text,
        registerProductLinkGwe: profileProductManagementTab?.registerProductLinkGwe,
        tryTheAppTitle: profileProductManagementTab?.tryTheAppTitle,
        tryTheAppText: profileProductManagementTab?.tryTheAppText,
        downloadOnAppStore: profileProductManagementTab?.downloadOnAppStore,
        downloadOnPlayStore: profileProductManagementTab?.downloadOnPlayStore,
        productManagementModalTitle: profileProductManagementTab?.productManagementModalTitle?.fields?.text,
        deleteProductModalTitle: profileProductManagementTab?.deleteProductModalTitle?.fields?.text,
        productManagementModalSubtitle: profileProductManagementTab?.productManagementModalSubtitle?.fields?.text,
        productManagementModalConfirm: profileProductManagementTab?.productManagementModalConfirm?.fields?.text,
        registeredProductLimitLabel: profileProductManagementTab?.registeredProductLimitLabel?.fields?.text,
        gcsDownErrorMessage: profileProductManagementTab?.gcsDownErrorMessage?.fields?.text,
        previousLabel: profileProductManagementTab?.previousLabel?.fields?.text,
        nextLabel: profileProductManagementTab?.nextLabel?.fields?.text,
        ...commonLabels
    };

    const commonControlBtnIconProps = {
        name: 'thinChevronDown',
        size: 2.4
    };

    const updateNumCarouselPages = () => {
        if (window && productCarouselRef?.current) {
            const carousel = productCarouselRef.current;
            const children = carousel.children;
            let fullCarouselWidth = 0;

            for (let i = 0; i < children.length; i++) {
                const element = children[i];

                let elementWidth = element.offsetWidth;
                const style = getComputedStyle(element);
                elementWidth += parseInt(style.marginLeft, 10) + parseInt(style.marginRight, 10);

                fullCarouselWidth += elementWidth;
            }
            // This fixes an issue where the result of "fullCarouselWidth / carousel.offsetWidth" was a low decimal point
            // and created less carousel pages than expected
            if (oralbProductList?.length % 3 === 1) {
                setNumCarouselPages(Math.ceil(fullCarouselWidth / carousel.offsetWidth));
            } else {
                setNumCarouselPages(Math.round(fullCarouselWidth / carousel.offsetWidth));
            }
        }
    };

    useEffect(() => {
        const handleResize = throttle(updateNumCarouselPages, 100);

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    });

    useEffect(() => {
        updateNumCarouselPages();
    }, []);

    useEffect(() => {
        if (!isObjectEmpty(oralbProductList)) {
            const product = oralbProductList.find(e => e.isPrimary);
            setCurrentProduct(product);
        }
        if (document.querySelector('.js-modal-container')) {
            Modal.setAppElement('.js-modal-container');
        }
    }, []);

    useEffect(() => {
            window.addEventListener('scroll', handleScroll);
            return () => {
                window.removeEventListener('scroll', handleScroll);
            }
        },
        [lastScroll]
    );

    useEffect(() => {
        toggleScroll(isModalOpen, lastScroll);
    }, [isModalOpen]);

    useEffect(() => {
        closeModal();
        updateNumCarouselPages();
    }, [oralbProductList]);

    const onScroll = useCallback(
        event => {
            if(!productCarouselRef.current) {
                return;
            }

            const currentRatio = event.target.scrollLeft / productCarouselRef.current.clientWidth;
            const min = Math.floor(currentRatio);

            let currentPosition = currentRatio < (min + 0.3) ? min : min + 1;
            currentPosition = Math.max(0, currentPosition);
            currentPosition = Math.min(oralbProductList.length, currentPosition);

            setCurrentPage(currentPosition);
        },
        [productCarouselRef.current],
    );

    const handleDotClick = useCallback(i => {
        if(productCarouselRef.current) {
            const nextY = productCarouselRef.current.clientWidth * i;
            if(productCarouselRef.current.scrollTo) {
                productCarouselRef.current.scrollTo({ left: nextY, behavior: 'smooth'});
            } else {
                productCarouselRef.current.scrollLeft = nextY;
            }
        }
    }, []);

    const handleClick = event => {
        if (dotNavRef?.current && productCarouselRef?.current) {
            let activePageIndex = parseInt(dotNavRef.current.querySelector('.ob-dot-nav__item--active .ob-dot-nav__button').dataset.index, 10);
            const clickedButton = event.currentTarget.dataset.controlButton;
            if (clickedButton === 'next' && activePageIndex + 1 < numCarouselPages) {
                activePageIndex = activePageIndex + 1;
            } else if (clickedButton === 'prev' && activePageIndex - 1 >= 0) {
                activePageIndex = activePageIndex - 1;
            }
            const nextY = productCarouselRef.current.clientWidth * activePageIndex;
            if (productCarouselRef.current.scrollTo) {
                productCarouselRef.current.scrollTo({ left: nextY, behavior: 'smooth'});
            } else {
                productCarouselRef.current.scrollLeft = nextY;
            }
        }
    };

    const handleScroll = useCallback(
        () => {
            if (!document.body.classList.contains('noScroll')) {
                setLastScroll(window.pageYOffset);
            }
        }
    );

    const closeModal = () => {
        setIsModalOpen(false);

        // Needed so the state only updates once the modal is closed and not during
        setTimeout(() => {
            setRequestedProduct({});
            setIsDeleting(false);
        }, closeModalTimeoutMS)
    };

    const openModal = event => {
        event.preventDefault();
        const product = JSON.parse(event.currentTarget.dataset.custom);
        setRequestedProduct(product);
        setIsModalOpen(true);
    };

    const deleteItem = () => {
        setIsProgress(true);
        const janrainProfileData = JSON.parse(getJanrainCaptureProfileDataFromLocalStorage());
        const newLocalStorageProductList = removeProductFromJanrainList(janrainProfileData.traitProductType, requestedProduct.id, requestedProduct.orderProductId);
        const newDisplayedProductList = removeProductFromDisplayedList(oralbProductList, requestedProduct.id, requestedProduct.orderProductId);
        // remove id from localStorage product. /entity.replace doesn't accept them. A new id will be generated.
        newLocalStorageProductList.forEach(product => {
            delete product.id;
        });

        let postObject = getPostReplaceEntityObject(janrainProfileData);
        postObject['traitProductType'] = newLocalStorageProductList;

        //remove primary product attributes
        if(requestedProduct?.isPrimary) {
            if(Array.isArray(newDisplayedProductList) && newDisplayedProductList.length > 0) {
                const oldestProductIndex = WarrantyExtensionConstants.janrinProfileOldestProductIndex;
                const oldestProduct = newDisplayedProductList[oldestProductIndex];
                const primaryProductSku = oldestProduct.productId;
                postObject['primaryProduct'] = primaryProductSku + '_' + oldestProduct.purchaseDate;
                const traitPrimaryBrushValue = {
                    traitValue: `${oldestProduct.typeCode ? oldestProduct.typeCode : ''}`,
                    productTypeCode : oldestProduct.typeCode
                }
                postObject['traitPrimaryBrush'] = traitPrimaryBrushValue;
                postWarrantyRegistrationUpdate(oldestProduct, janrainProfileData, true, false);
                newDisplayedProductList[oldestProductIndex].isPrimary = true;
            } else {
                postObject['primaryProduct'] = '';
                postObject['traitPrimaryBrush'] = {};
            }
        }

        const postReplaceSuccess = () => {
            setIsProgress(false);
        };
        // GCS post, callback janrain entity update on success
        postWarrantyRegistrationUpdate(requestedProduct, janrainProfileData, false, true, postReplaceEntityUpdate, function(){}, postObject, postReplaceSuccess);
        setOralbProductList(newDisplayedProductList);
    };

    const makePrimary = () => {
        setIsProgress(true);
        const janrainProfileData = JSON.parse(getJanrainCaptureProfileDataFromLocalStorage());
        let postObject = getPostReplaceEntityObject(janrainProfileData);

        const primaryProductSku = requestedProduct.productId;

        postObject['primaryProduct'] = primaryProductSku + '_' + requestedProduct.purchaseDate;
        const traitPrimaryBrushValue = {
            traitValue: `${requestedProduct.typeCode ? requestedProduct.typeCode : ''}`,
            productTypeCode : requestedProduct.typeCode
        }
        postObject['traitPrimaryBrush'] = traitPrimaryBrushValue;
        
        const postReplaceSuccess = () => {
            setIsProgress(false);
        };
        postWarrantyRegistrationUpdate(requestedProduct, janrainProfileData, true, false, postReplaceEntityUpdate, function(){}, postObject, postReplaceSuccess);
        // mark the previous primary product as non primary
        if(Array.isArray(oralbProductList)) {
            const primaryProducts = oralbProductList.filter((product) => product?.isPrimary);
            const previousPrimaryProduct = Array.isArray(primaryProducts) && primaryProducts.length > 0  && primaryProducts[0];
            previousPrimaryProduct && postWarrantyRegistrationUpdate(previousPrimaryProduct, janrainProfileData, false, false);
        }
        const newProductList = [];
        let primarySet = false;
        for (let i = 0; i < oralbProductList.length; i++) {
            const product = oralbProductList[i];
            if (!primarySet && product.productId === requestedProduct.productId &&
                product.orderProductId === requestedProduct.orderProductId &&
                product.id === requestedProduct.id) {
                product.isPrimary = true;
                primarySet = true;
            } else {
                product.isPrimary = false;
            }
            newProductList.push(product);
        }
        setOralbProductList(newProductList);
    };

    return (
        <div className={'ob-product-management js-modal-container'}>
            <MarkdownText className={'ob-product-management__text'} value={janrainLabels.profileIntroText} />
            <Eyebrow className={'ob-product-management__title'}>{janrainLabels.title}</Eyebrow>
            <MarkdownText className={'ob-product-management__text'} value={janrainLabels.productManagementText} />
            {isProgress && <div className='ob-product-management__spinner'></div>}
            {!isObjectEmpty(oralbProductList) &&
                <div className={'ob-product-management__carousel'}>
                    <ul className={`ob-product-management__product-list ${numCarouselPages === 1 || oralbProductList.length === 1 ? 'is-centered' : ''}`} onScroll={onScroll} ref={productCarouselRef}>
                        {oralbProductList.map((product, index) => (
                            <li className={'ob-product-management__product-list-item'} key={index}>
                                <div>
                                    <div className={'ob-product-management__product-image-wrapper'}>
                                        {product?.imageUrl ?
                                            <img src={optimizeImage(product?.imageUrl)} alt={product?.name || ''} className={'ob-product-management__product-image'} /> :
                                            <ResponsiveImage document={dummyImage} />}
                                    </div>
                                    <p className={'ob-product-management__product-title'} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(product?.title)}}></p>
                                </div>
                                <div>
                                    <Button
                                        className={'ob-product-management__product-date'}
                                        textTheme={true}
                                        tag={'a'}
                                        href={`${viewWarrantyLink?.pagePath?.fields?.slug}?product_id=${product?.id}`}
                                    >
                                        {janrainLabels.registeredOn + ' ' + product?.traitDate}
                                    </Button>
                                    <div className={'ob-product-management__product-buttons-container'}>
                                        {product.isPrimary ?
                                            <span
                                                data-size={'small-slim'}
                                                className={'ob-button ob-product-management__product-button --is-primary'}>
                                                {janrainLabels.primaryButtonLabel}
                                            </span>
                                            :
                                            <Button
                                                size={'small-slim'}
                                                borderThemeBlue={true}
                                                className={'ob-product-management__product-button ob-product-management__make-primary-btn'}
                                                dataCustom={customJsonstringify(product)}
                                                disabled={isProgress}
                                                onClick={openModal}>
                                                {janrainLabels.makePrimaryButtonLabel}
                                            </Button>
                                        }
                                        <Button
                                            textTheme={true}
                                            dataCustom={customJsonstringify(product)}
                                            onClick={(e) => {
                                                setIsDeleting(true);
                                                openModal(e);
                                            }}
                                            disabled={isProgress}
                                            className={'ob-product-management__product-button'}>
                                            {janrainLabels.deleteLabel}
                                        </Button>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>

                    {numCarouselPages > 1 &&
                        <div>
                            <button className={'ob-product-management__carousel--control-button'}
                                    data-control-button={'prev'}
                                    aria-label={janrainLabels?.previousLabel}
                                    onClick={handleClick}
                                    disabled={currentPage === 0}
                                    type={'button'}>
                                <span>
                                    <Icon {...commonControlBtnIconProps} rotate={90} />
                                </span>
                            </button>
                            <button className={'ob-product-management__carousel--control-button'}
                                    data-control-button={'next'}
                                    aria-label={janrainLabels?.nextLabel}
                                    onClick={handleClick}
                                    disabled={currentPage === numCarouselPages - 1}
                                    type={'button'}>
                                <span>
                                    <Icon {...commonControlBtnIconProps} rotate={-90} />
                                </span>
                            </button>
                            <div ref={dotNavRef}>
                                <DotNav count={numCarouselPages}
                                        onClick={handleDotClick}
                                        current={currentPage}
                                        color={'blue'} />
                            </div>
                        </div>
                    }
                </div>
            }
            {!isGCSAPIDown &&
                <div className={'ob-product-management__add-product-container'}>
                    {!isObjectEmpty(oralbProductList) && oralbProductList.length >= registeredProductLimit ? (
                        <>
                            <Button
                                size={'large-long'}
                                tag={'button'}
                                disabled={true}
                                className={'ob-profile__full-width-btn'}
                            >
                                {janrainLabels.registerProductLinkGwe?.fields?.title}
                            </Button>
                            <span className={'ob-product-management__product-limit-label'}>{janrainLabels.registeredProductLimitLabel}</span>
                        </>
                    ) : (
                        <Button
                            size={'large-long'}
                            tag={'a'}
                            href={janrainLabels.registerProductLinkGwe?.fields?.url}
                            className={'ob-profile__full-width-btn'}
                            onClick={deleteIsCreatingAccountFromLocalStorage}
                        >
                            {janrainLabels.registerProductLinkGwe?.fields?.title}
                        </Button>
                    )}
                </div>
            }

            {isGCSAPIDown &&
                <p className={'ob-input__error'}>{janrainLabels.gcsDownErrorMessage}</p>
            }

            <div className={'ob-product-management__app-container'}>
                <Eyebrow className={'ob-product-management__app-title'}>{janrainLabels.tryTheAppTitle}</Eyebrow>
                <MarkdownText className={'ob-product-management__text'} value={janrainLabels.tryTheAppText} />
                <ul className={'ob-product-management__app-list'}>
                    <li>
                        <a className={'ob-product-management__app-link'} href={janrainLabels.downloadOnAppStore?.fields?.url} target='_blank' rel='noopener noreferrer'>
                            <Image className={'ob-product-management__app-img'} image={janrainLabels.downloadOnAppStore?.fields?.image} />
                        </a>
                    </li>
                    <li>
                        <a className={'ob-product-management__app-link'} href={janrainLabels.downloadOnPlayStore?.fields?.url} target='_blank' rel='noopener noreferrer'>
                            <Image className={'ob-product-management__app-img'} image={janrainLabels.downloadOnPlayStore?.fields?.image} />
                        </a>
                    </li>
                </ul>
            </div>

            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                closeTimeoutMS={closeModalTimeoutMS}
                style={customStyles}
                htmlOpenClassName={'ob-profile__modal-overlay'}
            >
                <div className={'ob-profile__modal-container'}>
                    <div>
                        <p className={'ob-profile__modal-title'}>
                            {isDeleting ? (
                                <Label label={janrainLabels.deleteProductModalTitle} tokens={{productName: requestedProduct?.title}} />
                            ) : (
                                <Label label={janrainLabels.productManagementModalTitle} tokens={{productName: requestedProduct?.title}} />
                            )}
                        </p>
                        { !isDeleting && currentProduct?.cmsProductInfo?.title &&
                            <p className={'ob-profile__modal-subtitle'}>
                                <Label
                                    label={janrainLabels.productManagementModalSubtitle}
                                    tokens={{productName: currentProduct?.cmsProductInfo?.title}} />
                            </p>
                        }
                    </div>
                    <div>
                        <Button
                            className={'ob-profile__modal-confirm-btn'}
                            size={'large-long'}
                            onClick={isDeleting ? deleteItem : makePrimary}
                        >
                            {janrainLabels.productManagementModalConfirm}
                        </Button>
                        <Button
                            textTheme={true}
                            className={'ob-profile__modal-cancel-btn'}
                            onClick={closeModal}
                        >
                            {janrainLabels.modalCancelButton}
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    )
};

export default ProductManagement;
