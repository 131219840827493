import React, { useReducer } from 'react'
import dynamic from 'next/dynamic';
import { searchReducer,initialState } from '../../adapters/reducers/reducers';
import BaseContext from '../BaseContext';
const FAQBlock = dynamic(() => import('./FAQBlock'));
 
const FAQSearchComponent = (props) => {
const document = props?.document?.fields;
const [state, dispatch] = useReducer(searchReducer, initialState) 
  return (
    <div>
      <BaseContext.Provider value={[state, dispatch]}>
        <BaseContext.Consumer>
        { context => context &&
        <React.Fragment>
          <FAQBlock document={document} context={context}/>
        </React.Fragment> }
      </BaseContext.Consumer>
      </BaseContext.Provider>
  </div>
  )
}


export default React.memo(FAQSearchComponent)
