import React from 'react';
import { IFrameBlockConstants } from '../../adapters/helpers/Constants'
import PropTypes from 'prop-types';

export default function IFrameBlock({ document }) {

    const name = document?.fields?.name;
    const url = document?.fields?.url;

    return (<div className='ob-connect'>
        <iframe
            className={'ob-connect-iframe'}
            title={name}
            src={url} 
            id={IFrameBlockConstants.id}
            frameBorder="0" 
            marginHeight="0" 
            marginWidth="0" 
            width="100%" 
            height="100%" 
            scrolling="auto" />
    </div>);
}


IFrameBlock.propTypes = {
    document: PropTypes.object
};